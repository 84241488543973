@import '../../../styles/variables.scss';

.Header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;

  // .none {
  //   display: none;
  // }

  // .block {
  //   display: block;
  // }

  .pointer {
    cursor: pointer;
  }

  .nav-new {
    width: 15px;
    height: 15px;
    top: 15px;
    line-height: 15px;
    border-radius: 15px;
    font-size: 8px;
    background: #ed5100;
    text-align: center;
    color: #fff;
    margin-bottom: 10px;
    margin-left: 5px;
    font-weight: bold;
  }

  li {
    list-style-type: none;
  }

  .main-box {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
  }

  .top {
    background-image: linear-gradient(to right, #003448 0%, #003448 50%, #2c5367 50%, #2c5367 100%);

    .main-box {
      background: var(--Dark-Blue, #003448);

      .language {
        color: var(--White, #fff);
        text-align: center;
        // font-family: Avenir;
        font-family: 'Avenir LT Std Medium';
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        margin-top: 10px;
        margin-bottom: 10px;
        cursor: pointer;
      }

      .column {
        width: 1px;
        height: 20px;
        margin-left: 12px;
        margin-right: 12px;
        opacity: 0.34;
        background: #e1e6ed;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .triangle {
        border: 20px solid;
        border-color: #2c5367 #2c5367 #2c5367 #003448;
        width: 0;
        height: 0;
        margin-left: 8px;
      }

      .login-icon {
        background: #2c5367;
        padding-top: 6px;
        padding-bottom: 10px;
      }

      .login-box {
        background: #2c5367;

        .login {
          color: var(--White, #fff);
          text-align: center;
          //font-family: Avenir;
          font-family: 'Avenir LT Std Medium';
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          margin-top: 10px;
          margin-bottom: 10px;
          margin-right: 95px;
          margin-left: 4px;
          cursor: pointer;
        }
      }
    }
  }

  .tail {
    background: #fff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);

    .main-box {
      .logo {
        padding-top: 22px;
        padding-bottom: 22px;
        padding-left: 86px;
        cursor: pointer;
      }

      .list {
        margin-right: 110px;

        .touch:hover .block {
          display: block;
        }

        .block {
          display: none;
        }

        .list-ui {
          //width: 680px;

          li {
            height: 100%;
            display: flex;
            align-items: center;
            position: relative;
            margin-left: 50px;
          }

          .active {
            position: relative;

            &::after {
              content: '';
              width: 100%;
              background-color: $yellow;
              height: 3px;
              position: absolute;
              bottom: 0px;
            }

            .navigation {
              color: var(--Dark-Blue, #003448);
            }
          }

          li:hover .navigation {
            color: var(--Dark-Blue, #003448);
            text-align: center;
            font-feature-settings: 'clig' off, 'liga' off;

            /* Menu/Title_selected */
            font-family: 'Avenir LT Std Heavy';
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            /* 150% */
          }

          li:hover::after {
            content: '';
            width: 100%;
            background-color: $yellow;
            height: 3px;
            position: absolute;
            bottom: 0px;
          }

          .unfold-box {
            position: absolute;
            top: 80px;
            right: -20px;

            .unfold {
              width: 468px;
              border-radius: 5px;
              border: 1px solid rgba(219, 223, 227, 0.3);
              box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
              background-color: #fff;

              .unfold-title {
                color: var(--Knock-gray-900, #1a1f36);
                font-feature-settings: 'clig' off, 'liga' off;

                /* Session_title */
                font-family: Avenir LT Std;
                font-size: 18px;
                font-style: normal;
                font-weight: 750;
                line-height: 20px;
                /* 111.111% */
                padding: 12px 16px;
                text-align: left;
              }

              .message-box {
                border-radius: 5px;
                border: 1px solid rgba(219, 223, 227, 0.3);
                background: #fff;
                box-shadow: 0px 3px 12px 0px rgba(217, 226, 233, 0.5);
                margin-top: 16px;
                margin-left: 14px;
                margin-right: 14px;
                padding: 8px;

                .massage-title {
                  color: (--Knock-gray-900, #1a1f36);
                  font-feature-settings: 'clig' off, 'liga' off;
                  font-family: Avenir LT Std;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 750;
                  line-height: 20px;
                  margin-left: 8px;
                  margin-right: 8px;
                }

                .time {
                  color: var(--Knock-gray-200, #a5acb8);

                  /* Regular/Small */
                  font-family: Inter;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px;
                  /* 142.857% */
                  margin: 16px 8px;
                }
              }

              .next {
                height: 24px;
                margin-top: 16px;
                text-align: center;
              }
            }
          }

          .navigation {
            color: #9b9b9b;
            text-align: center;
            //font-family: Avenir;
            font-family: 'Avenir LT Std Medium';
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
            /* 150% */
            text-decoration: none;
            cursor: pointer;
          }

          .nav-new {
            width: 15px;
            height: 15px;
            top: 15px;
            line-height: 15px;
            border-radius: 15px;
            font-size: 8px;
            background: $yellow;
            text-align: center;
            color: #fff;
            margin-bottom: 10px;
            margin-left: 5px;
            font-weight: bold;
          }
        }
      }
    }
  }

  .mobile-logo {
    display: none;
  }

  .mobile-tools {
    display: none;
  }

  .mobile-menus {
    display: flex;
  }
}

@media only screen and (max-width: 992px) {

  .Header {
    &.mobile-header {
      bottom: 0;
      background-color: #003448;

      .mobile-menus {
        display: block;
      }

      .toggle-box {
        span {
          background-color: transparent;

          &::before {
            top: 0;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transition: all .15s ease-in;
            -o-transition: all .15s ease-in;
            -moz-transition: all .15s ease-in;
            -o-transition: all .15s ease-in;
            -webkit-transition: all .15s ease-in;
          }

          &::after {
            bottom: 0;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transition: all .15s ease-in;
            -o-transition: all .15s ease-in;
            -moz-transition: all .15s ease-in;
            -o-transition: all .15s ease-in;
            -webkit-transition: all .15s ease-in;
          }
        }
      }
    }

    .mobile-header {
      background-color: #003448;
    }

    .top {
      display: none;
    }

    .mobile-menus {
      display: none;
    }

    .tail {
      box-shadow: none;

      .main-box {
        max-width: 100%;
        background-color: #003448;
        flex-direction: column;


        .logo {
          padding-left: 0px;
        }

        .list {
          margin-right: 0;
          background-color: #003448;

          .touch:hover {
            .block {
              display: none;
            }
          }

          .list-ui {
            // display: flex;
            display: none;
            flex-direction: column;
            width: 100%;

            li {
              padding: 20px;
              margin-left: 0;

              &:hover {
                &::after {
                  display: none;
                }

                .navigation {
                  color: #FFC81C;
                }
              }

              .navigation {
                color: #fff;
              }
            }

            .active {
              &::after {
                display: none;
              }

              .navigation {
                color: #FFC81C;
              }
            }
          }
        }
      }
    }

    .pc-logo {
      display: none;
    }

    .logo {
      width: 100%;
    }

    .mobile-logo {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 27px;
      }
    }

    .mobile-tools {
      width: 100%;
      display: flex;
      color: #fff;
      padding: 20px;

      .tools-list {
        >.column {
          border-right: 1px solid #E1E6ED;
          margin-right: 12px;
          margin-left: 12px;

          &:last-child {
            border-right: none;
          }
        }
      }

      .mobile-logout-box {
        width: 170px;
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        padding: 10px 20px;
        background-color: #2C5367;
        display: flex;
        justify-content: flex-end;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-top: 25px solid transparent;
          border-bottom: 25px solid transparent;
          border-left: 25px solid #003448;

        }

        .mobile-logout {
          display: flex;
          gap: 10px;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .toggle-box {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;

    span {
      background-color: #fff;
      border-radius: 2px;
      display: block;
      height: 2px;
      position: relative;
      width: 25px;

      &::before,
      &::after {
        background-color: #fff;
        border-radius: 5px;
        content: "";
        display: block;
        height: 2px;
        left: 0;
        position: absolute;
        transition: all .1s ease-out;
        width: 25px;
      }

      &::after {
        bottom: -7px;
      }

      &::before {
        top: -7px;
      }
    }
  }
}