.tax-questionnaire {
    padding-top: 180px;
    padding-bottom: 100px;
    min-height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;

    .submit-button {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 29px;
        padding-right: 29px;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        
        height: 42px;
        width: 200px;
        background: #FFC81C;
        border-radius: 5px;
        border: none;
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 22px;
        font-family: 'Avenir LT Std Medium';
        color: #0d3448;
        margin-top: 20px;
    }
}